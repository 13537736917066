import React from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import sitemap from '../../../data/sitemap';
import Container from '../../Container';
import Stack from '../../Stack';
import Mobile from './Mobile';
import styles from './Navigation.module.scss';

export default function Navigation({ dark, toggleDark }) {
  const { file } = useStaticQuery(query);
  const Icon = dark ? FaSun : FaMoon;

  return (
    <Container as='nav' className={styles.container}>
      <Link to='/' className={styles.logo}>
        <Img
          alt='logo'
          className={styles.logo_asset}
          fluid={file.childImageSharp.fluid}
        />
        <span className={styles.logo_text}>PokeMeow</span>
      </Link>
      <Stack spacing='element' align='center' className={styles.items}>
        {Object.entries(sitemap).map(([key, { label, href, ...props }]) =>
          href ? (
            <a
              className={styles.item}
              href={href}
              target='_blank'
              rel='noreferrer'
              key={key}
            >
              {label}
            </a>
          ) : (
            <Link
              className={styles.item}
              activeClassName={styles.active}
              key={key}
              {...props}
            >
              {label}
            </Link>
          ),
        )}
      </Stack>
      <button
        aria-label='Toggle dark mode'
        className={styles.icon}
        onClick={toggleDark}
      >
        <Icon />
      </button>
      <Mobile />
    </Container>
  );
}

const query = graphql`
  {
    file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
