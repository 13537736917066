import links from './links';

export default {
  home: {
    label: 'Home',
    to: '/',
  },
  commands: {
    label: 'Commands',
    to: '/commands',
  },
  wiki: {
    label: 'Wiki',
    to: '/wiki',
  },
  vote: links.vote,
  bot: links.bot,
};
