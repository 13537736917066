import React, { useState } from 'react';
import classnames from 'classnames';
import { FaBars } from 'react-icons/fa';
import { Link } from 'gatsby';
import sitemap from '../../../../data/sitemap';
import Stack from '../../../Stack';
import styles from './Mobile.module.scss';

export default function Mobile() {
  const [menu, setMenu] = useState();
  return (
    <div className={styles.container}>
      <button
        aria-label='Toggle Menu'
        className={styles.menu}
        onClick={() => setMenu(!menu)}
      >
        <FaBars className={styles.bars} />
      </button>
      <Stack
        spacing='element'
        direction='column'
        className={classnames(
          styles.dropdown,
          menu && styles[`dropdown--show`],
        )}
      >
        {Object.entries(sitemap).map(
          ([key, { label, href, ...props }], index, { length }) => {
            const config = {
              onBlur: () => index === length - 1 && setMenu(false),
              onClick: () => setMenu(false),
              tabIndex: menu ? 0 : -1,
              className: styles.item,
              key,
            };

            if (!href) {
              return (
                <Link activeClassName={styles.active} {...config} {...props}>
                  {label}
                </Link>
              );
            }

            return (
              <a rel='noreferrer' target='_blank' href={href} {...config}>
                {label}
              </a>
            );
          },
        )}
      </Stack>
    </div>
  );
}
