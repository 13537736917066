import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import formatDate from '../../../utils/formatDate';
import links from '../../../data/links';
import Container from '../../Container';
import Stack from '../../Stack';
import styles from './Footer.module.scss';

const { bot, vote, ..._links } = links;

export default function Footer() {
  const { site } = useStaticQuery(query);
  return (
    <Container
      containerClassName={styles.layout}
      className={styles.container}
      as='footer'
    >
      <Stack className={styles.content} direction='column' spacing='none'>
        <span className={styles.text}>
          Made with{' '}
          <span role='img' aria-label='heart'>
            💖
          </span>{' '}
          by PokeMeow
        </span>
        <span>
          Last updated @{' '}
          <span className={styles[`text--primary`]}>
            {formatDate(new Date(site.siteMetadata.updated_at))}
          </span>
        </span>
      </Stack>
      <Stack className={styles.social} spacing='element'>
        {Object.entries(_links).map(([key, { href, icon: Icon }]) => (
          <a
            className={styles.link}
            target='_blank'
            rel='noreferrer'
            href={href}
            key={key}
          >
            <Icon className={styles.icon} />
            <span className={styles.sr}>Link to {key}</span>
          </a>
        ))}
      </Stack>
    </Container>
  );
}

const query = graphql`
  {
    site {
      siteMetadata {
        updated_at
      }
    }
  }
`;
