import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Container.module.scss';

export default function Container({
  containerClassName,
  as: Component,
  background,
  className,
  children,
  ...props
}) {
  return (
    <div
      className={classnames(
        styles[`container--${background}`],
        containerClassName,
        styles.container,
      )}
    >
      <Component className={classnames(styles.content, className)} {...props}>
        {children}
      </Component>
    </div>
  );
}

Container.defaultProps = {
  background: `background`,
  as: `div`,
};

Container.propTypes = {
  as: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object]),
  background: PropTypes.oneOf(styles.colors.split(', ')),
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};
