import { useState, useEffect } from 'react';

export default function useAnimate() {
  const [animate, setAnimate] = useState();
  useEffect(() => {
    let mounted = true;
    window.requestAnimationFrame(() => {
      if (mounted) {
        setAnimate(true);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);
  return animate || 'hide';
}
