import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Stack.module.scss';

const Stack = React.forwardRef(
  (
    {
      as: Component,
      className,
      direction,
      children,
      justify,
      spacing,
      align,
      ...props
    },
    ref,
  ) => {
    return (
      <Component
        className={classnames(
          spacing !== 'none' && styles[`container-spacing--${spacing}`],
          styles[`container-direction--${direction}`],
          styles[`container-justify--${justify}`],
          styles[`container-align--${align}`],
          styles.container,
          className,
        )}
        {...props}
        ref={ref}
      >
        {React.Children.map(children, (child) =>
          React.isValidElement(child) ? (
            React.cloneElement(child, {
              className: classnames(
                spacing !== 'none' && styles[`item-spacing--${spacing}`],
                child.props.className,
                styles.item,
              ),
            })
          ) : (
            <div
              className={classnames(
                spacing !== 'none' && styles[`item-spacing--${spacing}`],
                styles.item,
              )}
            >
              {child}
            </div>
          ),
        )}
      </Component>
    );
  },
);

Stack.displayName = `Stack`;
Stack.defaultProps = {
  spacing: 'component',
  direction: 'row',
  justify: 'flex-start',
  align: 'flex-start',
  as: 'div',
};

Stack.propTypes = {
  spacing: PropTypes.oneOf(['none', ...styles.spacing.split(', ')]),
  direction: PropTypes.oneOf(styles.direction.split(' ')),
  justify: PropTypes.oneOf(styles.align.split(' ')),
  align: PropTypes.oneOf(styles.align.split(' ')),
  className: PropTypes.string,
  children: PropTypes.node,
  as: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object]),
};

export default Stack;
