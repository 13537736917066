import {
  FaReddit,
  FaInfoCircle,
  FaPatreon,
  FaDiscord,
  FaVoteYea,
  FaTwitch,
} from 'react-icons/fa';

export default {
  twitch: {
    label: 'Twitch',
    href: 'https://www.twitch.tv/pokemeowofficial',
    icon: FaTwitch,
  },
  reddit: {
    label: 'Reddit',
    href: 'https://www.reddit.com/r/PokeMeow',
    icon: FaReddit,
  },
  wiki: {
    label: 'Wiki',
    href:
      'https://pokemeow.com/wiki/starting-guide',
    icon: FaInfoCircle,
  },
  patreon: {
    label: 'Patreon',
    href: 'https://www.patreon.com/PokeMeowBot',
    icon: FaPatreon,
  },
  support: {
    label: 'Support Channel',
    href: 'https://discord.gg/eN45HEn',
    icon: FaDiscord,
  },
  vote: {
    label: 'Vote now',
    href: 'https://top.gg/bot/664508672713424926/vote',
    icon: FaVoteYea,
  },
  bot: {
    label: 'Invite to server',
    href:
      'https://discordapp.com/oauth2/authorize?client_id=664508672713424926&scope=bot&permissions=1074129984',
    icon: FaDiscord,
  },
};
