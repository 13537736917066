import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Navigation from './Navigation';
import Footer from './Footer';
import Seo from '../Seo';
import style from './Layout.module.scss';
import useAnimate from '../../utils/hooks/useAnimate';

export default function Layout({
  className,
  children,
  description,
  title,
  lang,
  meta,
}) {
  const animate = useAnimate();
  const initState = useRef(
    typeof window !== 'undefined' &&
      window.localStorage.getItem('dark') === 'true',
  );
  const [dark, setDark] = useState(initState.current);
  const toggleDark = () => {
    localStorage.setItem('dark', !dark);
    setDark(!dark);
  };

  return (
    <main
      className={classnames(
        style.container,
        dark && 'dark',
        animate,
        className,
      )}
    >
      <Seo title={title} description={description} meta={meta} lang={lang} />
      <Navigation dark={dark} toggleDark={toggleDark} />
      {children}
      <Footer />
    </main>
  );
}

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  ...Seo.propTypes,
};
